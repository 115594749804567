<template>
  <v-container>
    <base-card color="transparent" tile flat class="ma-4">
      <v-row>
        <v-col>
          <v-container>
            <base-card
              color="transparent"
              tile
              flat
              min-width="130"
              class="ma-4"
              style="color: white; font-size: 20px; text-align: center"
            >
              <v-row justify="center" class="mb-4">
                <v-icon size="60" color="white">
                  {{ "mdi-file-document-multiple-outline" }}</v-icon
                >
              </v-row>
              <v-row justify="center"> {{ spanishStatus.title1.title }} </v-row>
              <v-divider style="border-width: 3px" dark class="my-9" />
              <v-row justify="center" style="font-size: 15px">
                {{ spanishStatus.title1.blurb }}
              </v-row>
            </base-card>
          </v-container>
        </v-col>
        <v-col>
          <v-container>
            <base-card
              color="transparent"
              tile
              flat
              min-width="130"
              class="ma-4"
              style="color: white; font-size: 20px; text-align: center"
            >
              <v-row justify="center" class="mb-4">
                <v-icon size="60" color="white">
                  {{ "mdi-shield-check-outline" }}</v-icon
                >
              </v-row>
              <v-row justify="center">{{ spanishStatus.title2.title }}</v-row>
              <v-divider style="border-width: 3px" dark class="my-9" />
              <v-row justify="center" style="font-size: 15px">
                {{ spanishStatus.title2.blurb }}
              </v-row>
            </base-card>
          </v-container>
        </v-col>
        <v-col>
          <v-container>
            <base-card
              color="transparent"
              tile
              flat
              min-width="130"
              class="ma-4"
              style="color: white; font-size: 20px; text-align: center"
            >
              <v-row justify="center" class="mb-4">
                <v-icon size="60" color="white">
                  {{ "mdi-folder-search-outline" }}</v-icon
                >
              </v-row>
              <v-row justify="center">{{ spanishStatus.title3.title }}</v-row>
              <v-divider style="border-width: 3px" dark class="my-9" />
              <v-row justify="center" style="font-size: 15px">
                {{ spanishStatus.title3.blurb }}
              </v-row>
            </base-card>
          </v-container>
        </v-col>
        <v-col>
          <v-container>
            <base-card
              color="transparent"
              tile
              flat
              min-width="130"
              class="ma-4"
              style="color: white; font-size: 20px; text-align: center"
            >
              <v-row justify="center" class="mb-4">
                <v-icon size="60" color="white">
                  {{ "mdi-handshake-outline" }}</v-icon
                >
              </v-row>
              <v-row justify="center">{{ spanishStatus.title4.title }}</v-row>
              <v-divider style="border-width: 3px" dark class="my-9" />
              <v-row justify="center" style="font-size: 15px">
                {{ spanishStatus.title4.blurb }}
              </v-row>
            </base-card>
          </v-container>
        </v-col>
        <v-col>
          <v-container>
            <base-card
              color="transparent"
              tile
              flat
              min-width="130"
              class="ma-4"
              style="color: white; font-size: 20px; text-align: center"
            >
              <v-row justify="center" class="mb-4">
                <v-icon size="60" color="white">
                  {{ "mdi-sign-real-estate" }}</v-icon
                >
              </v-row>
              <v-row justify="center">{{ spanishStatus.title5.title }}</v-row>
              <v-divider style="border-width: 3px" dark class="my-9" />
              <v-row justify="center" style="font-size: 15px">
                {{ spanishStatus.title5.blurb }}
              </v-row>
            </base-card>
          </v-container>
        </v-col>
      </v-row>
    </base-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HomeIntroSnip",

  data() {
    return {
      english: {
        title1: {
          title: "TAXES",
          blurb: "We prepare taxes efficiently with years of experience. Taxes of all kinds, personal, commercial, corporate or incorporated. all kinds of taxes.",
        },
        title2: {
          title: "COMMERCIAL INSURANCE",
          blurb:
            "Our specialist can help you find the coverage for your business customized to meet your needs.",
        },
        title3: {
          title: "AUDITS",
          blurb:
            "Talk to us about how we can help you with your Workers Comp, General Liability, IRS and MN Revenue audits and get you back in business",
        },
        title4: {
          title: "INCORPORATIONS",
          blurb: "Get your business going fast and easy. Register correctly with the State.",
        },
        title5: {
          title: "REAL ESTATE",
          blurb:
            "We have the dedication and experience to help you buy or sell. Make your next dream home come true.",
        },
      },

      spanish: {
        title1: {
          title: "TAXES",
          blurb:
            "Preparamos impuestos de manera eficiente con años de experiencia. Impuestos de todo tipo personales, comerciales de sociedades o incorporados. Todo tipo de impuestos.",
        },
        title2: {
          title: "SEGUROS COMERCIALES",
          blurb:
            "Nuestro especialista puede ayudarlo a encontrar la cobertura para su negocio personalizada para satisfacer sus necesidades.",
        },
        title3: {
          title: "AUDITORIAS",
          blurb:
            "Hable con nosotros sobre cómo podemos ayudarlo con sus auditorías de Workers Comp, General Liability, IRS y MN Revenue y ayudarlo a regresar a su negocio.",
        },
        title4: {
          title: "INCORPORACIONES",
          blurb: "Haga que su negocio funcione rápido y fácil. Registralo correctamente con el Estado.",
        },
        title5: {
          title: "REAL ESTATE",
          blurb:
            "Tenemos la dedicación y la experiencia para ayudarle a comprar o vender. Haz realidad la próxima casa de tus sueños.",
        },
      },
    };
  },

  computed: {
    ...mapGetters(["getLanguage"]),

    spanishStatus() {
      if (this.getLanguage) {
        return this.spanish;
      } else {
        return this.english;
      }
    },
  },
};
</script>
