<template>
  <div class="bannerBar">
    <v-sheet height="100%" color="#29264a">
      <v-img height="1000" src="../../assets/logo8.png">
        <v-container
          ><v-card flat tile color="transparent" class="ma-3">
            <v-row>
              <v-col>
                <v-row justify="start" style="color: white; font-size: 75px">
                  {{ spanishStatus.title1 }}
                </v-row>
                <v-row justify="start" style="color: white; font-size: 75px">
                  {{ spanishStatus.title2 }}
                </v-row>
                <v-row justify="start" style="color: #5dcbe6; font-size: 75px">
                  {{ spanishStatus.title3 }}
                </v-row>
                <v-row justify="start" style="color: #5dcbe6; font-size: 75px">
                  {{ spanishStatus.title4 }}
                </v-row>
                <div
                  style="
                    color: white;
                    font-family: Roboto, sans-serif;
                    font-size: 75px;
                  "
                >
                  <v-row align="center" justify="center"> VJ </v-row>
                  <v-row align="center" justify="center">Total Services </v-row>
                  <v-row align="center" justify="center">and Insurance </v-row>
                </div></v-col
              >
            </v-row>
          </v-card>
        </v-container>
      </v-img>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BannerBar",

  data() {
    return {
      english: {
        title1: "Total",
        title2: "Services",
        title3: "and",
        title4: "Insurance",
      },

      spanish: {
        title1: "Servicios",
        title2: "Totales",
        title3: "y",
        title4: "Seguros",
      },
    };
  },

  computed: {
    ...mapGetters(["getLanguage"]),

    spanishStatus() {
      if (this.getLanguage) {
        return this.spanish;
      } else {
        return this.english;
      }
    },
  },
};
</script>
