<template>
  <v-app-bar app color="#344d6c" dark height="75">
    <v-container>
      <v-row class="d-flex flex-nowrap my-2">
        <v-col style="max-width: 50px">
          <v-app-bar-nav-icon @click="toggleDrawer" />
        </v-col>

        <v-col align="center">
          <v-img
            class="my-1"
            alt="VJ Logo"
            src="../../assets/logo.png"
            transition="scale-transition"
            max-height="72"
            height="100%"
            max-width="100"
            contain
          />
        </v-col>

        <v-row class="d-flex flex-nowrap my-2">
          <v-col
            align="center"
            class="hidden-sm-and-down"
            v-for="(link, i) in appBarLinks"
            :key="i"
          >
            <v-btn
              :to="link.to"
              :href="link.href"
              v-if="!link.last"
              text
              rounded
              x-large
              outlined
              style="border-width: 2px; border-color: white"
              class="myBtn ma-2"
              @click="onClick($event, link)"
            >
              <span v-if="!spanishSwitch">
                {{ link.text }}
              </span>
              <span v-else>
                {{ link.textSp }}
              </span>
            </v-btn>
            <v-btn
              :to="link.to"
              :href="link.href"
              v-else
              text
              x-large
              class="myBtn ma-2"
              rounded
              outlined
              style="border-width: 2px; border-color: white"
              @click="onClick($event, link)"
            >
              <span v-if="!spanishSwitch">
                {{ link.text }}
              </span>
              <span v-else>
                {{ link.textSp }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
        <v-col>
          <v-sheet class="pa-5" color="transparent">
            <v-switch
              v-model="spanishSwitch"
              
              :label="'Español'"
              @click="changeLanguage"
            ></v-switch>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
// Utilities
// Utilities
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
      spanishSwitch: true,
    };
  },

  computed: {
    ...mapGetters(["appBarLinks"]),
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
      return 1000;
    },
  },

  methods: {
    ...mapActions([
      // `mapActions` also supports payloads:
      "setLanguage", // map `this.checkOutById(id)` to `this.$store.dispatch('checkOutById', id)`
    ]),

    changeLanguage() {
      this.setLanguage(this.spanishSwitch);
    },

    ...mapMutations(["toggleDrawer"]),
    onClick(e, item) {
      e.stopPropagation();

      if (item.to || !item.href) return;

      if (this.$route.name != "home") {
        this.$router.push({ name: "home" });
      } else {
        this.$vuetify.goTo(item.href, { duration: 500, easing: "linear" });
      }
    },
  },
};
</script>
