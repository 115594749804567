<template>
  <v-main id="home" class="pt-0">
    <router-view
      >
    </router-view>
  </v-main>
</template>

<script>
export default {
  name: "CoreView",
};
</script>
