export const links = {
  state: {
    items: [
      {
        text: "Home",
        textSp: "Servicios",
        href: "#home",
        icon: "mdi-home",
        last: false,
        bottom: true,
        top: true,
      },
      {
        text: "About Us",
        textSp: "¿Quiénes somos?",
        href: "#joinUs",
        icon: "mdi-information",
        last: false,
        bottom: true,
        top: true,
      },
      {
        text: "Contact",

        textSp: "Visítanos",
        href: "#contact",
        icon: "mdi-email",
        last: true,
        bottom: true,
        top: true,
      },
    ],

    spanish: true,
  },
  getters: {
    appBarLinks: (state) => {
      const topLinks = [];
      for (const link of state.items) {
        if (!link.top) {
          continue;
        }
        topLinks.push(link);
      }
      return topLinks;
    },
    allLinks: (state) => {
      const topLinks = [];
      for (const link of state.items) {
        topLinks.push(link);
      }
      return topLinks;
    },
    footerLinks: (state) => {
      const bottomLinks = [];
      for (const link of state.items) {
        if (!link.bottom) {
          continue;
        }
        bottomLinks.push(link);
      }
      return bottomLinks;
    },

    getLanguage: (state) => {
      return state.spanish;
    },
  },
  mutations: {
    setSpanish: (state, payload) => (state.spanish = payload),
  },

  actions: {
    setLanguage({ commit }, status) {
      return new Promise((resolve) => {
        setTimeout(() => {
          commit("setSpanish", status);
          resolve();
        }, 250);
      });
    },
  },
};
