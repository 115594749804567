<template>
  <v-container id="joinUs" tag="section">
    <base-card flat tile class="ma-4">
      <v-row>
        <v-col align="center">
          <base-subheading>VJ Total Services and Insurance</base-subheading>
        </v-col>
        <div class="text4 text-wrap ma-4" style="text-align: center">
          <p>
            {{ spanishStatus }}
          </p>
        </div>
      </v-row>
    </base-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HomeAboutUs",

  data() {
    return {
      english:
        "Here at VJ Total Services and Insurance we specialize in reviewing and carefully satisfying each and every one of your business needs. For us, no business is small. We have been serving our community since 2008 and have hundreds of satisfied customers who give us the gift of their choice over the years. As independent agents we work with several companies. Call us today to get your quote completely free. Don't wait another minute, for us you are priority number one. We also have consulting and preparation of all kinds of taxes. Don't forget, we are a comprehensive services company. Call us today to get your quote completely free. Do not wait another minute, for us you are priority number one.",

      spanish:
        "Aqui en VJ Total Services and Insurance nos especializamos en revisar y cuidadosamente satisfacer todas y cada una de las necesidades de tu negocio. Para nosotros ningun negocio es pequeño. Hemos estado sirviendo a nuestra comunidad desde el 2008 y tenemos cientos de clientes satisfechos con nuestro servicio y que nos dan el regalo de su preferencia a lo largo de los años. Como agentes independientes trabajamos con varias compañias. Llamanos hoy mismo para obtener tu cuota completamente gratis. No esperes un minuto mas, para nosotros eres prioridad numero uno.Tambien tenemos consultoria y elaboracion de taxes de todo tipo no lo olvides somos una empresa de servicios integrales. Llamanos hoy mismo para obtener tu cuota completamente gratis. No esperes un minuto mas, para nosotros eres prioridad numero uno.",
    };
  },

  computed: {
    ...mapGetters(["getLanguage"]),

    spanishStatus() {
      if (this.getLanguage) {
        return this.spanish;
      } else {
        return this.english;
      }
    },
  },
};
</script>
