<template>
  <v-container id="joinUs" tag="section">
    <base-card color="transparent" flat tile class="ma-4">
      <v-row>
        <v-col align="center">
          <base-subheading style="color: white">{{
            spanishStatus
          }}</base-subheading>

          <v-container>
            <base-card color="transparent" tile flat class="ma-4">
              <v-row>
                <v-col>
                  <v-container>
                    <base-card
                      color="transparent"
                      tile
                      flat
                      class="ma-4"
                      style="color: white; font-size: 20px; text-align: center"
                    >
                      <v-row justify="center" class="mb-4">
                        <v-btn
                          elevation="2"
                          icon
                          outlined
                          class="emailBtn ma-1 text4"
                          style="font-size: 20px; background-color: transparent"
                          rounded
                          size="90"
                          target="_blank"
                          href="https://www.facebook.com/VJTotalServicesAndInsurance"
                        >
                          <v-icon color="white" size="80">
                            {{ "mdi-facebook" }}</v-icon
                          ></v-btn
                        >
                      </v-row>
                    </base-card>
                  </v-container>
                </v-col>
              </v-row>
            </base-card>
          </v-container>
        </v-col>
      </v-row>
    </base-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "HomeAboutUs",

  data() {
    return {
      english: "Follow Us",

      spanish: "Síganos",
    };
  },

  computed: {
    ...mapGetters(["getLanguage"]),

    spanishStatus() {
      if (this.getLanguage) {
        return this.spanish;
      } else {
        return this.english;
      }
    },
  },
};
</script>
