<template>
  <v-container id="contact" tag="section">
    <base-card flat tile color="transparent">
      <v-row>
        <v-col align="center">
          <base-subheading style="color: white">{{
            spanishStatus
          }}</base-subheading></v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-container>
            <v-card flat tile min-width="200" height="160" color="transparent"
              style="color: white; font-size: 25px; text-align: center">
              <v-row justify="center" class="mb-4">
                <v-btn elevation="2" icon outlined class="emailBtn ma-1 text4"
                  style="font-size: 20px; background-color: white" rounded x-large @click="call()"><v-icon>{{ "mdi-phone"
                  }}</v-icon></v-btn>
              </v-row>
              <v-row justify="center" class="mb-4">
                +1.612-267-3107 <br />+1.651-442-5154
              </v-row>
            </v-card>
          </v-container>
          <v-divider style="border-width: 3px" dark />
        </v-col>
        <v-col>
          <v-container>
            <v-card flat tile min-width="200" height="148" color="transparent" style="color: white; font-size: 25px">
              <v-col align="end">
                <v-sheet height="50" color="transparent" />
                <v-row justify="center">
                  <!-- <ModalView :showIcon="2" /> -->


                  <v-btn class="emailBtn ma-1 text4" style="font-size: 20px; background-color: white" outlined depressed
                    elevation="3" x-large @click="sendEmail"><v-icon left>{{ "mdi-email" }}</v-icon>{{ "E-Mail" }}</v-btn>
                </v-row>
              </v-col>
            </v-card>
          </v-container>
          <v-divider style="border-width: 3px" dark />
        </v-col>
        <v-col>
          <v-container>
            <v-card flat tile min-width="200" height="160" color="transparent"
              style="color: white; font-size: 25px; text-align: center">
              <v-row justify="center" class="mb-4">
                <v-btn elevation="2" icon outlined class="emailBtn ma-1 text4"
                  style="font-size: 20px; background-color: white" rounded x-large
                  href="https://goo.gl/maps/G9vanGW8Yp4X4Ca4A" target="_blank"><v-icon>{{ "mdi-home-map-marker"
                  }}</v-icon></v-btn>
              </v-row>
              <v-row justify="center" class="mb-4">
                1004 S Robert St<br />
                West St Paul, MN 55118
              </v-row>
            </v-card>
          </v-container>
          <v-divider style="border-width: 3px" dark />
        </v-col>
      </v-row>
      <v-col align="center" class="text4" style="font-size: 25px">
        <v-sheet color="transparent" height="150" />
        <v-sheet height="500">
          <home-map-view />
        </v-sheet>
      </v-col>
    </base-card>
  </v-container>
</template>

<script>
//import ModalView from "../modal/ModalView.vue";
import HomeMapView from "../home/MapView.vue";
import { mapGetters } from "vuex";

export default {
  name: "HomeContact",

  data() {
    return {
      english: "Stay in Touch",

      spanish: "Visítanos",
    };
  },

  components: {
    // ModalView,
    HomeMapView,
  },

  methods: {
    call() {
      window.open("tel:+1.651-442-5154");
    },
    sendEmail() {
      window.location.href = 'mailto:totalservices@live.com';
    },
  },

  computed: {
    ...mapGetters(["getLanguage"]),

    spanishStatus() {
      if (this.getLanguage) {
        return this.spanish;
      } else {
        return this.english;
      }
    },
  },
};
</script>
