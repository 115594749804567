<template>
  <div>
    <v-sheet height="100%" color="#2e7ca8">
      <inro-snip />
    </v-sheet>

    <v-sheet height="75" color="#2e7ca8" />
    <about-us />
    <v-sheet height="100%" color="#29264a">
      <follow-us />
    </v-sheet>
    <v-sheet height="100%" color="#2e7ca8">
      <contact />
    </v-sheet>
  </div>
</template>

<script>
import Contact from "../components/home/Contact.vue";
import InroSnip from "../components/home/IntroSnip.vue";
import AboutUs from "../components/home/AboutUs.vue";
import FollowUs from "../components/home/FollowUs.vue";
export default {
  name: "HomeSection",

  components: {
    Contact,
    InroSnip,
    AboutUs,
    FollowUs,
  },
};
</script>
