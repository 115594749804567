<template>
  <div id="viewDiv" class="balt-theme"></div>
</template>

<script>
/* eslint-disable */
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";

export default {
  name: "HomeMapView",

  mounted() {
    this.loadMap();
  },
  methods: {
    loadMap() {
      const map = new Map({
        basemap: "osm", // Use OpenStreetMap basemap
      });

      const view = new MapView({
        map: map,
        center: [-93.08075280864253, 44.91243046896775], // Longitude, latitude   
        zoom: 14, // Zoom level
        container: "viewDiv", // Div element
      });

      view.when(() => {
        console.log("MapView is ready");

        const vjOfficePoint = {
          type: "point",
          longitude: -93.08042, // Office location
          latitude: 44.91576,
        };

        const graphicsLayer = new GraphicsLayer({});
        map.add(graphicsLayer);

        const vjMarker = {
          type: "picture-marker",
          url: require("../../assets/logo.png"),
          width: 50,
          height: 50,
        };

        const pointGraphic = new Graphic({
          geometry: vjOfficePoint,
          symbol: vjMarker,
        });

        graphicsLayer.add(pointGraphic);
      }, (error) => {
        console.error("MapView failed to load: ", error);
      });
    },
  },
};
</script>

<style>
/* esri styles */
@import url("https://js.arcgis.com/4.24/esri/themes/dark/main.css");

#viewDiv {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}
</style>
